<template>
  <div class="search_container">
    <div class="header">
      <van-nav-bar
        title="筛选工地"
        left-arrow
        @click-left="handleBack"
        safe-area-inset-top
      >
      </van-nav-bar>
    </div>
    <form action="/">
      <van-search
        v-model="searchVal"
        show-action
        placeholder="输入工地名称搜索"
        @search="onSearch"
        @cancel="onCancel"
      />
    </form>
    <article class="mycase-article">
      <van-list>
        <div
          v-for="item in searchCase"
          :key="item.buildId"
          class="building-item"
          @click="goToDetails(item.buildId)"
        >
          <div
            class="building-img"
            :style="{ '--show': item.isTop == 0 ? `none` : `block` }"
          >
            <img
              :src="
                item.headImgUrl
                  ? `${imgUrl}/${item.headImgUrl}`
                  : `${defaultImg}`
              "
              alt=""
              @click="loadPanoramic"
            />
          </div>
          <div class="building-info">
            <div class="info1" @click.stop>
              <div class="title-text">{{ limitWords(item.title, 20) }}</div>
              <icon-svg
                iconClass="iconshare-2"
                class="share"
                style="width: 20px; height: 20px; margin-left: 6px"
                @click="handleShare(item)"
              />
            </div>
            <div class="info2">
              <div class="room-describle">
                {{ item.typeName }} | {{ item.area }}m² | {{ item.styleName }}
              </div>
              <div class="building-stage">
                <svg
                  style="width: 12px; height: 12px; line-height: 12px"
                  fill="#289FFF"
                  t="1648619964035"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="2453"
                  width="200"
                  height="200"
                >
                  <path
                    d="M511.914 63.99c-247.012 0-447.925 200.912-447.925 447.924s200.913 447.925 447.925 447.925 447.925-200.913 447.925-447.925S758.926 63.989 511.914 63.989z m0 831.687c-211.577 0-383.763-172.186-383.763-383.763 0-211.577 172.014-383.763 383.763-383.763s383.763 172.014 383.763 383.763-172.186 383.763-383.763 383.763z"
                    fill=""
                    p-id="2454"
                  ></path>
                  <path
                    d="M672.06 511.914H512.085v-223.79c0-17.718-14.277-32.167-31.995-32.167-17.717 0-31.994 14.45-31.994 32.167V544.08c0 17.717 14.277 31.994 31.994 31.994H672.06c17.718 0 32.167-14.277 32.167-31.994-0.172-17.89-14.621-32.167-32.167-32.167z"
                    fill=""
                    p-id="2455"
                  ></path>
                </svg>
                {{ item.stageName }}
              </div>
            </div>
            <div class="info3" @click.stop>
              <div class="location-text">
                <svg
                  t="1648621241158"
                  fill="#BFBFBF"
                  style="width: 12px; height: 12px"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="3268"
                  width="200"
                  height="200"
                >
                  <path
                    d="M533.162667 846.813867a23.825067 23.825067 0 0 1-32.768 0C326.587733 682.0864 238.933333 550.570667 238.933333 448.477867a277.845333 277.845333 0 1 1 555.690667 0c0 102.126933-87.688533 233.608533-261.461333 398.336z m-246.613334-398.336c0 82.090667 76.288 199.2704 230.229334 348.091733 153.941333-148.821333 230.1952-266.001067 230.1952-348.0576a230.1952 230.1952 0 1 0-460.424534 0z m230.229334 87.3472a119.057067 119.057067 0 1 1 0-238.148267 119.057067 119.057067 0 0 1 0 238.148267z m0-47.650134a71.441067 71.441067 0 1 0 0-142.882133 71.441067 71.441067 0 0 0 0 142.882133z"
                    fill="#999999"
                    p-id="3269"
                  ></path>
                </svg>
                {{ item.region }} {{ item.apartmentName }}
              </div>
              <icon-svg
                v-if="tabIndex == 1"
                style="width: 16px; height: 16px"
                iconClass="icontrash-2"
                class="del"
                @click="handleDelCase(item.buildId)"
              />
            </div>
            <div class="info4" v-if="tabIndex == 1">
              <div class="clue-num">
                <icon-svg
                  iconClass="iconfile-text"
                  class=""
                  style="
                    width: 16px;
                    height: 16px;
                    color: #333;
                    margin-right: 6px;
                  "
                />
                线索{{ item.countOfClues }}
              </div>
              <div class="browse-num">
                <icon-svg
                  iconClass="iconeye"
                  class=""
                  style="
                    width: 16px;
                    height: 16px;
                    color: #333;
                    margin-right: 6px;
                  "
                />
                浏览{{ item.countOfViews }}
              </div>
              <div class="forward-num">
                <icon-svg
                  iconClass="iconshare"
                  class=""
                  style="
                    width: 16px;
                    height: 16px;
                    color: #333;
                    margin-right: 6px;
                  "
                />
                转发{{ item.countOfForward }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </article>
    <!-- 删除 -->
    <div class="del-wrapper">
      <van-overlay :show="showDel" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p>删除</p>
            <p>是否删除此工地？</p>
            <van-grid clickable :column-num="2">
              <van-grid-item @click="handleConfirm('cancel')"
                ><span>取消</span></van-grid-item
              >
              <van-grid-item @click="handleConfirm"
                ><span>确定</span></van-grid-item
              >
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>
    <!-- 弹出分享框 -->
    <div class="share_box">
      <van-share-sheet
        v-model:show="showShare"
        title="分享"
        :options="options"
        cancel-text="取消"
        @select="onSelect"
        safe-area-inset-bottom
      >
      </van-share-sheet>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  findBuildSiteByPageApp,
  findMyBuildSiteByPage,
  deleteBuildSiteApp,
} from "@/api/newBuildSite";
import { shareBuildToMiniProgram } from "@/utils/commonUtil";
import { buildReprint } from "@/api/common";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      showDel: false,
      imgUrl: process.env.VUE_APP_WATCH_FILE_API,
      defaultImg: require("@/assets/images/buildSite-default.png"),
      morenImg: require("../../assets/images/buildSite-default.png"),
      searchCase: {},
      searchVal: route.query.searchVal ? route.query.searchVal : "",
      tabIndex: Number(route.query.tabIndex),
      showShare: false,
      caseItem: {},
      options: [
        { name: "微信", icon: require("@/assets/icons/weChat2.png") },
        { name: "朋友圈", icon: require("@/assets/icons/friends2.png") },
      ],
    });
    const handleBack = () => {
      router.back();
    };

    //打开分享微信
    const handleShare = (item) => {
      state.showShare = true;
      state.buildId = item.buildId;
      state.caseItem = item;
    };
    //微信分享
    const onSelect = (index) => {
      buildReprint({ busiId: state.buildId });
      // if (index.name === "微信") {
      //   shareCaseToMiniProgram(state.caseItem);
      // }
      shareBuildToMiniProgram(state.caseItem, index);
    };
    const onSearch = async () => {
      if (state.tabIndex == 0) {
        let result = await findBuildSiteByPageApp({
          isMaterial: 1,
          search: state.searchVal,
          size: 30,
        });
        state.searchCase = result.records;
      } else {
        let res = await findMyBuildSiteByPage({
          search: state.searchVal,
          size: 30,
        });
        state.searchCase = res.records;
      }
    };

    //取消
    const onCancel = () => {
      state.searchVal = "";
    };
    const loadToDetails = (buildId) => {
      router.push({
        path: "/buildSite/details",
        query: {
          buildId: buildId,
          caseType: state.caseType,
          comfrom: "search",
          searchVal: state.searchVal,
          twoType: route.query.twoType,
        },
      });
    };

    //删除
    const handleDelCase = (id) => {
      state.showDel = !state.showDel;
      state.buildId = id;
    };
    //确认删除
    const handleConfirm = async (isDel) => {
      if (isDel === "cancel") {
        state.showDel = !state.showDel;
      } else {
        let result = await deleteBuildSiteApp(state.buildId);
        if (result) {
          state.showDel = !state.showDel;
          onSearch();
        }
      }
    };
    const limitWords = (e, num) => {
      if (e.length > num) {
        return e.substring(0, num) + "...";
      } else {
        return e;
      }
    };

    const goToDetails = (buildId) => {
      console.log(buildId);
      router.push({
        path: "/siteDetail",
        name: "siteDetail",
        query: {
          buildId: buildId,
        },
        params: {
          fromPoster:
            route.params.fromPoster === "true" ? route.params.fromPoster : "",
          addPoster:
            route.params.addPoster === "true" ? route.params.addPoster : "",
        },
      });
    };
    return {
      handleDelCase,
      handleConfirm,
      ...toRefs(state),
      loadToDetails,
      onCancel,
      onSearch,
      handleBack,
      handleShare,
      onSelect,
      limitWords,
      goToDetails,
    };
  },
};
</script>

<style lang="scss">
.search_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
  }
  .van-search__action {
    color: #ff6e01;
  }
  .Is-comto-material {
    font-size: 14px;
    .comto-material {
      font-weight: 600;
      padding: 18px;
      text-align: left;
    }
    .select-comin {
      .selectBox {
        float: left;
        color: #999999;
        width: 52px;
        height: 28px;
        border: 1px solid #999999;
        line-height: 28px;
        margin-left: 18px;
        border-radius: 4px;
        box-sizing: border-box;
      }
      .bgColor {
        color: #fff;
        background-color: #289fff;
        border: none;
      }
    }
  }
  .mycase-article {
    flex: 1;
    overflow-x: hidden;
    //  margin-bottom: 16px;
    .van-cell {
      padding: 0;
    }
    .van-cell::after {
      border: none;
    }
    .mycase-box-info {
      flex: 1;
      padding: 5px 16px;
      .case-box {
        font-size: 16px;
        text-align: left;
        .cover-img {
          height: 160px;
          > img {
            display: block;
            border-radius: 4px;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .case-info {
          //  height: auto;
          position: relative;
          display: flex;
          width: 343px;
          justify-content: space-between;
          .title {
            //  float: left;
            position: relative;
            width: 330px;
            > p {
              margin: 0;
              padding: 0;
            }
            > p:nth-child(1) {
              font-weight: 600;
              margin-top: 8px;
            }
            > p:nth-child(2) {
              font-weight: 400;
              font-size: 14px;
              margin: 4px 0;
              color: #666666;
            }
          }
          .share-del {
            top: 10px;
            position: relative;
            .share {
              height: 20px;
              width: 20px;
              background: url("../../assets/icons/share-3.png") no-repeat;
            }
            .del {
              height: 20px;
              margin-top: 15px;
              width: 20px;
              background: url("../../assets/icons/trash-2.png") no-repeat;
            }
          }
        }
      }
    }
    .my_case {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 34px;
      border-bottom: 1px solid #f1f3f6;
      border-top: 1px solid #f1f3f6;
      font-size: 12px;
      > div {
        height: 34px;
        display: flex;
        color: #999999;
        align-items: center;
        img {
          margin-right: 3px;
        }
      }
    }
  }

  .building-item {
    padding: 16px 16px 2px 16px;
    .building-img {
      position: relative;
      height: 160px;
      border-radius: 4px;
      overflow: hidden;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .building-img::before {
      display: var(--show);
      position: absolute;
      height: 18px;
      font-size: 12px;
      line-height: 20px;
      padding: 0 8px;
      color: #fff;
      content: "置顶";
      left: 0;
      top: 0;
      background-color: #ff6e01;
      border-radius: 4px 0 4px 0;
    }

    .building-info {
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      > div {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
      }
      .info1 {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        .title-text {
          flex: 1;
          text-align: start;
        }
      }
      .info2 {
        display: flex;
        flex-direction: row;
        > div {
          flex: 1;
          font-size: 12px;
          text-align: start;
        }
        .room-describle {
          color: #666;
        }
        .building-stage {
          color: #289fff;
        }
      }
      .info3 {
        display: flex;
        flex-direction: row;
        .location-text {
          text-align: start;
          font-size: 12px;
          color: #bfbfbf;
          flex: 1;
        }
      }

      .info4 {
        padding-top: 16px;
        font-size: 12px;
        color: #999;
        justify-content: space-evenly;
      }
    }
  }

  //  删除
  .del-wrapper {
    .van-overlay {
      z-index: 102;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        font-size: 14px;
        width: 303px;
        height: 133px;
        border-radius: 10px;
        color: #333333;
        background-color: #fff;
        > div {
          > div:nth-child(1) {
            .van-hairline {
              border-radius: 0 0 0 10px;
              padding: 0;
              color: #999999;
            }
          }
          > div:nth-child(2) {
            .van-hairline {
              border-radius: 0 0 10px 0;
              padding: 0;
            }
            [class*="van-hairline"]::after {
              border-right: none;
            }
          }
        }
        [class*="van-hairline"]::after {
          border-bottom: none;
        }
        > p {
          font-weight: 600;
          font-size: 14px;
          margin: 17px 16px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 46px;
          // height: 100%;
          // width: 100%;
        }
      }
    }
  }
  //   分享
  .share_box {
    .van-share-sheet__cancel::before {
      height: 0;
    }
    .van-share-sheet__cancel {
      height: 48px;
      width: 343px;
      background-color: #f7f7f7;
      color: #333333;
      border-radius: 5px;
      //    position: relative;
      //    transform: translateX(50%);
      margin-left: 15px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .van-share-sheet__options {
      color: #333333;
      display: flex;
      padding: 0 20px;
      justify-content: space-around;
    }
  }
}
</style>
