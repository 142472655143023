import {get,del} from '../../utils/request'

/**
 * 获取全部工地
 */
 export function findBuildSiteByPageApp(params){
    return get(
        '/case/build/findBuildSiteByPageApp',
        params
    )
}
/**
 * 获取我的工地
 */
export function findMyBuildSiteByPage(params){
    return get(
        '/case/build/findMyBuildSiteByPage',
        params
    )
}

/**
 * 根据id获取工地详情
 */
export function findBuildSiteByIdApp(id){
    
    return get(
        `/case/build/findBuildSiteByIdApp/${id}`,
    )
}

/**
 * 删除工地
 */
 export function deleteBuildSiteApp(id){
    return del(
       `/case/build/deleteBuildSiteApp/${id}`,
    )
}

/**
 * 获取二维码地址
 */
 export function getBuildSiteDetailsUrl(buildId){
    return get(
        `/case/build/getBuildSiteDetailsUrl/2/${buildId}`
    )
}

/****
 * 获取初始菜单
 * */ 
 
 export function initFindBuildSiteList(){
    return get(
        `/case/build/initFindBuildSiteList`
    )
}